import Api from './api/apiGPC'
import { buildRequestParamsObj } from 'help/requests'

const baseUrl = '/contrato'

const Agreements = {
  getAgreements(data) {
    return Api.request(`${baseUrl}/buscarContratos`, buildRequestParamsObj(data))
  },
  getAgreementsCount(data) {
    return Api.request(`${baseUrl}/buscarContratosResumo`, buildRequestParamsObj(data))
  },
  salvarAtualizarContrato(data) {
    return Api.request(`${baseUrl}/salvarAtualizarContrato`, buildRequestParamsObj(data))
  },
  buscarAlavancasContrato(data) {
    return Api.request(`${baseUrl}/buscarAlavancasContrato`, buildRequestParamsObj(data))
  },
  getContractLever(data) {
    return Api.request('apuracaopack/buscarAlavancasContrato', buildRequestParamsObj(data))
  },
  buscarDetalhesContrato(data) {
    return Api.request(`${baseUrl}/buscarDetalhesContrato`, buildRequestParamsObj(data))
  },
  buscarContratoEdicao(data) {
    return Api.request(`${baseUrl}/buscarContratoEdicao`, buildRequestParamsObj(data))
  },
  buscarDetalheAlavancasContrato(data) {
    return Api.request(`${baseUrl}/buscarDetalheAlavancasContrato`, buildRequestParamsObj(data))
  },
  buscarStatusAlavancaContrato(data) {
    return Api.request(`${baseUrl}/buscarStatusAlavancaContrato`, buildRequestParamsObj(data))
  },
  cancelarContratos(data) {
    return Api.request(`${baseUrl}/cancelarContratos`, buildRequestParamsObj(data))
  },
  confirmarContratosEmGrupo(data) {
    return Api.request(`${baseUrl}/confirmarContratosEmGrupo`, buildRequestParamsObj(data))
  },
  aprovarContratos(data) {
    return Api.request(`${baseUrl}/aprovarContratos`, buildRequestParamsObj(data))
  },
  reprovarContratos(data) {
    return Api.request(`${baseUrl}/reprovarContratos`, buildRequestParamsObj(data))
  },
  encerrarContratos(data) {
    return Api.request(`${baseUrl}/encerrarContratos`, buildRequestParamsObj(data))
  },
  exportarContrato(data) {
    return Api.request('/impressao/imprimir', buildRequestParamsObj(data))
  },
  exportarAlteracoesIntegracaoClienteSap(data) {
    return Api.request(`${baseUrl}/exportarAlteracoesIntegracaoClienteSap`, buildRequestParamsObj(data))
  },
  buscarStatusDivergencia(data) {
    return Api.request(`${baseUrl}/buscarStatusDivergencia`, buildRequestParamsObj(data))
  },
  aprovarAlavancas(data) {
    return Api.request(`${baseUrl}/aprovarAlavancas`, buildRequestParamsObj(data))
  },
  reprovarAlavancas(data) {
    return Api.request(`${baseUrl}/reprovarAlavancas`, buildRequestParamsObj(data))
  },
  encerrarAlavancas(data) {
    return Api.request(`${baseUrl}/encerrarAlavancas`, buildRequestParamsObj(data))
  },
  exportarConsolidado(data) {
    return Api.request(`${baseUrl}/exportarConsolidado`, buildRequestParamsObj(data))
  },
  exportarRelatorioResumoDescontos(data) {
    return Api.request('/relatorioresumodescontos/exportarRelatorioResumoDescontos', buildRequestParamsObj(data))
  },
  exportarHistoricoContrato(data) {
    return Api.request(`${baseUrl}/exportarHistoricoContrato`, buildRequestParamsObj(data))
  },
  enviarAlavancasAprovacao(data) {
    return Api.request(`${baseUrl}/enviarAlavancasAprovacao`, buildRequestParamsObj(data))
  },
  importarAssinatura(data) {
    return Api.request('/assinatura/importar', buildRequestParamsObj(data))
  },
  exportarAssinatura(data) {
    return Api.request('/assinatura/exportar', buildRequestParamsObj(data))
  },
  excluirAssinatura(data) {
    return Api.request('/assinatura/excluir', buildRequestParamsObj(data))
  },
  listarAssinaturas(data) {
    return Api.request('/assinatura/listar', buildRequestParamsObj(data))
  },
  buscarClientesContrato(data) {
    return Api.request(`${baseUrl}/buscarClientesContrato`, buildRequestParamsObj(data))
  },
  buscarRecomendacoesNaoRecomendada(data) {
    return Api.request(`${baseUrl}/buscarRecomendacoesNaoRecomendada`, buildRequestParamsObj(data))
  },
  buscarDivergenciasContratosAlavancas(data) {
    return Api.request(`${baseUrl}/buscarDivergenciasContratosAlavancas`, buildRequestParamsObj(data))
  },
  validarTotaisEventosCota(data) {
    return Api.request(`${baseUrl}/validarTotaisEventoCota`, buildRequestParamsObj(data))
  },
  exportarRelatorioEventosCota(data) {
    return Api.request(`${baseUrl}/exportarRelatorioEventosCota`, buildRequestParamsObj(data))
  },
  buscarValorAnualAlavancaEventosCota(data) {
    return Api.request(`${baseUrl}/buscarValorAnualAlavancaEventosCota`, buildRequestParamsObj(data))
  },
  buscarPerfilUltimoAprovador(data) {
    return Api.request('perfil/buscarPerfilContratoAprovado', buildRequestParamsObj(data))
  },
  buscarAlavancasContratoSapOn(data) {
    return Api.request(`${baseUrl}/buscarAlavancasContratoSapOn`, buildRequestParamsObj(data))
  },
  exportContratoSapOn(data) {
    return Api.request(`${baseUrl}/exportContratoSapOn`, buildRequestParamsObj(data))
  },
  importarContratoSapOn(data) {
    return Api.request(`${baseUrl}/importarContratoSapOnTela`, buildRequestParamsObj(data))
  },
  buscarContratoRetornadoEmCadastro(data) {
    return Api.request(`${baseUrl}/buscarContratoRetornadoEmCadastro`, buildRequestParamsObj(data))
  },
  prorrogarContrato(data) {
    return Api.request(`${baseUrl}/prorrogar`, buildRequestParamsObj(data))
  },
  exportarTemplateMassivo(data) {
    return Api.request(`${baseUrl}/exportarTemplateMassivo`, buildRequestParamsObj(data))
  },
}

export default Agreements
