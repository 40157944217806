import { lazy } from 'react'
import i18n from 'common/i18n'

const Workflow = lazy(() => import('containers/Workflow'))
const WorkflowRegister = lazy(() => import('containers/Workflow/register'))

const route = [
  {
    name: i18n.t('routes.workflow.index'),
    path: '/workflow',
    main: Workflow,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_FLUXO_APROVACAO', 'ROLE_VISUALIZAR_CADASTRO_FLUXO_APROVACAO']
  },

  {
    name: i18n.t('routes.workflow.register'),
    path: '/workflow/register',
    main: WorkflowRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_FLUXO_APROVACAO']
  },
  {
    name: i18n.t('routes.workflow.edit'),
    path: '/workflow/edit/:id',
    main: WorkflowRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_FLUXO_APROVACAO']
  }
]

export default route
