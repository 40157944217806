import Api from './api/apiGPC'
import { buildRequestParamsObj } from 'help/requests'

const baseUrl = '/usuario'
const resetPasswordHeaders = {
  'Content-Type': 'application/json'
}

const firstAcess = {
  'Content-Type': 'application/json'
}

const User = {
  buscarUsuarioLogadoMenu(data) {
    return Api.request(`${baseUrl}/buscarUsuarioLogadoMenu`, buildRequestParamsObj(data))
  },
  buscarUsuarios(data) {
    return Api.request(`${baseUrl}/buscarUsuarios`, buildRequestParamsObj(data))
  },
  buscarUsuariosPrimeiroAcesso(data) {
    return Api.request(`${baseUrl}/buscarUsuariosPrimeiroAcesso`, buildRequestParamsObj(data))
  },
  buscarUsuariosSolicitacaoEdicao(data) {
    return Api.request(`${baseUrl}/buscarUsuariosSolicitacaoEdicao`, buildRequestParamsObj(data))
  },
  buscarUsuariosAprovacao(data) {
    return Api.request(`${baseUrl}/buscarUsuariosAprovacao`, buildRequestParamsObj(data))
  },
  buscarUsuariosEmValidacao(data) {
    return Api.request(`${baseUrl}/buscarUsuariosEmValidacao`, buildRequestParamsObj(data))
  },
  configurarPermissoesPrimeiroAcesso(data) {
    return Api.request(`${baseUrl}/configurarPermissoesPrimeiroAcesso`, buildRequestParamsObj(data))
  },
  configuracaoEdicaoUsuario(data) {
    return Api.request(`${baseUrl}/configuracaoEdicaoUsuario`, buildRequestParamsObj(data))
  },
  aprovarPrimeiroAcesso(data) {
    return Api.request(`${baseUrl}/aprovarPrimeiroAcesso`, buildRequestParamsObj(data))
  },
  reprovarPrimeiroAcesso(data) {
    return Api.request(`${baseUrl}/reprovarPrimeiroAcesso`, buildRequestParamsObj(data))
  },
  aprovarSolicitacaoEdicao(data) {
    return Api.request(`${baseUrl}/aprovarSolicitacaoEdicao`, buildRequestParamsObj(data))
  },
  reprovarSolicitacaoEdicao(data) {
    return Api.request(`${baseUrl}/reprovarSolicitacaoEdicao`, buildRequestParamsObj(data))
  },
  atualizarUltimoAcesso(data) {
    return Api.request(`${baseUrl}/atualizarUltimoAcesso`, buildRequestParamsObj(data))
  },
  buscarPermissoesMenu(data) {
    return Api.request(`${baseUrl}/buscarPermissoesMenu`, buildRequestParamsObj(data))
  },
  buscarUsuariosGrid(data) {
    return Api.request(`${baseUrl}/buscarUsuariosGrid`, buildRequestParamsObj(data))
  },
  buscarPerfis(data) {
    return Api.request('perfil/buscarInput', buildRequestParamsObj(data))
  },
  exportarUsuarios(data) {
    return Api.request(`${baseUrl}/export`, buildRequestParamsObj(data))
  },
  salvarAtualizarUsuario(data) {
    return Api.request(`${baseUrl}/salvarAtualizarUsuario`, buildRequestParamsObj(data))
  },
  removerUsuario(data) {
    return Api.request(`${baseUrl}/removerUsuario`, buildRequestParamsObj(data))
  },
  buscarPorId(data) {
    return Api.request(`${baseUrl}/buscarPorId`, buildRequestParamsObj(data))
  },
  changePassword(data) {
    return Api.request(`${baseUrl}/alterarSenha`, buildRequestParamsObj(data))
  },
  resetPassword(data) {
    return Api.request('autenticacao/resetarSenha', buildRequestParamsObj(data, resetPasswordHeaders))
  },
  getUserProfile(data) {
    return Api.request(`${baseUrl}/buscarUsuarioLogadoPerfil`, buildRequestParamsObj(data))
  },
  respondeuNps(idUsuario, accessToken) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
    return Api.request(
      `${baseUrl}/respondeuNps?idUsuario=${idUsuario}`,
      { data: null, method: 'GET', headers: headers }
    )
  },
  salvarPesquisaNps(data) {
    return Api.request(`${baseUrl}/salvarPesquisaNps`, buildRequestParamsObj(data))
  },
  solicitarAcesso(data) {
    return Api.request(`${baseUrl}/solicitarAcesso`, buildRequestParamsObj(data, firstAcess))
  },
  solicitarEdicaoUsuario(data) {
    return Api.request(`${baseUrl}/solicitacaoEdicaoUsuario`, buildRequestParamsObj(data))
  },
  reprovarSolicitacaoEmValidacao(data) {
    return Api.request(`${baseUrl}/reprovarSolicitacaoEmValidacao`, buildRequestParamsObj(data))
  },
  buscarUsuariosReprovados(data) {
    return Api.request(`${baseUrl}/buscarUsuariosReprovados`, buildRequestParamsObj(data))
  },
  totalizadorAbas(data) {
    return Api.request(`${baseUrl}/totalizadorAbas`, buildRequestParamsObj(data))
  },

  getTodasOrganizacaoVendaAtiva() {
    const headers = {
      'Content-Type': 'application/json'
    }
    return Api.request(
      'autenticacao/organizacao-vendas',
      { data: null, method: 'GET', headers: headers }
    )
  },

  getCargosPorOrganizacaoVendas(data) {
    return Api.request('autenticacao/cargos', buildRequestParamsObj(data, firstAcess))
  }

}

export default User
