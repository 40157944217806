import { lazy } from 'react'
import i18n from 'common/i18n'

const AdminRegisterOffice = lazy(() => import('containers/AdminRegisterOffice'))
const AdminOfficeRegister = lazy(() => import('containers/AdminRegisterOffice/register'))

const route = [
  {
    name: i18n.t('routes.adminRegisterOffice.index'),
    path: '/office',
    main: AdminRegisterOffice,
    exact: true,
    permissions: ['ROLE_EDITAR_CARGO']
  },
  {
    name: i18n.t('routes.adminRegisterOffice.register'),
    path: '/office/register',
    main: AdminOfficeRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CARGO']
  },
  {
    name: i18n.t('routes.adminRegisterOffice.edit'),
    path: '/office/edit/:id',
    main: AdminOfficeRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CARGO']
  }
]

export default route
