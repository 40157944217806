import general from './general'
import agreements from './agreements'
import menu from './menu'
import calculations from './calculations'
import tradeAgreementsAscertainment from './tradeAgreementsAscertainment'
import generalFilters from './generalFilters'
import payments from './payments'
import routes from './routes'
import calendar from './calendar'
import cycleFolder from './cycleFolder'
import user from './user'
import cva from './cva'
import channel from './channel'
import category from './category'
import customerAndSap from './customerAndSap'
import productIntegration from './productIntegration'
import clientIntegration from './clientIntegration'
import economicGroup from './economicGroup'
import home from './home'
import productsGroup from './productsGroup'
import impulseItem from './impulseItem'
import brands from './brands'
import microregion from './microregion'
import approvalFlow from './approvalFlow'
import internalOrder from './internalOrder'
import innovationProducts from './innovationProducts'
import profile from './profile'
import region from './region'
import budgetType from './budgetType'
import commodity from './commodity'
import sku from './sku'
import segment from './segment'
import subregion from './subregion'
import productPpa from './productPpa'
import questionsGoalExecution from './questionsGoalExecution'
import packCategory from './packCategory'
import customerPdv from './customerPdv'
import targetExecutionProduct from './targetExecutionProduct'
import targetExecutionCategory from './targetExecutionCategory'
import ppaProduct from './ppaProduct'
import ascertainment from './ascertainment'
import chat from './chat'
import nps from './nps'
import pdvStoreIntegration from './pdvStoreIntegration'
import percentageValue from './percentageValue'
import sellin from './sellin'
import importsExportsDashboard from './importsExportsDashboard'
import inconsistenciesDashboard from './inconsistenciesDashboard'
import errorPage from './errorPage'
import ascertainmentACTPM from './ascertainmentACTPM'
import distributionBudget from './distributionBudget'
import distributionBudgetCalculation from './distributionBudgetCalculation'
import workflow from './workflow'
import adminToa from './adminToa'
import adminRegisterOffice from './adminRegisterOffice'
import approvePayments from './approvePayments'

export default {
  general,
  generalFilters,
  agreements,
  menu,
  ascertainmentACTPM,
  calculations,
  tradeAgreementsAscertainment,
  payments,
  routes,
  calendar,
  cycleFolder,
  user,
  cva,
  channel,
  category,
  customerAndSap,
  productIntegration,
  clientIntegration,
  economicGroup,
  home,
  productsGroup,
  impulseItem,
  brands,
  microregion,
  approvalFlow,
  profile,
  internalOrder,
  innovationProducts,
  budgetType,
  commodity,
  subregion,
  sku,
  targetExecutionCategory,
  targetExecutionProduct,
  segment,
  region,
  packCategory,
  questionsGoalExecution,
  customerPdv,
  productPpa,
  ppaProduct,
  ascertainment,
  pdvStoreIntegration,
  chat,
  nps,
  percentageValue,
  sellin,
  importsExportsDashboard,
  inconsistenciesDashboard,
  errorPage,
  distributionBudget,
  distributionBudgetCalculation,
  workflow,
  adminToa,
  adminRegisterOffice,
  approvePayments
}
