import { lazy } from 'react'
import i18n from 'common/i18n'

const AdminToa = lazy(() => import('containers/AdminToa'))
const AdminToaRegister = lazy(() => import('containers/AdminToa/register'))

const route = [
  {
    name: i18n.t('routes.adminToa.index'),
    path: '/toa',
    main: AdminToa,
    exact: true,
    permissions: ['ROLE_CADASTRAR_FLUXO_TOA']
  },
  {
    name: i18n.t('routes.adminToa.register'),
    path: '/toa/register',
    main: AdminToaRegister,
    exact: true
  }
]

export default route
