import { lazy } from 'react'
import i18n from 'common/i18n'

const DistributionBudget = lazy(() => import('containers/DistributionBudget'))
const DistributionBudgetCalculation = lazy(() => import('containers/DistributionBudgetCalculation'))
const DistributeMoneyDistributionBudget = lazy(() => import('containers/DistributionBudgetCalculation/components/DistributeMoney/DistributeMoneyDistributionBudget'))
const DevolutionBudgetReport = lazy(() => import('containers/DevolutionBudgetReport'))
const DistributionBudgetReport = lazy(() => import('containers/DistributionBudgetReport'))

const baseUrl = '/distribution-budget'

const route = [
  {
    name: i18n.t('routes.distributionBudget.index'),
    path: baseUrl,
    main: DistributionBudget,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_DISTRIBUICAO_VERBAS', 'ROLE_IMPORTA_DISTRIBUICAO_VERBAS']

  },
  {
    name: i18n.t('routes.distributionBudget.calculation'),
    path: '/distribution-calc',
    main: DistributionBudgetCalculation,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_APURACAO_VERBA_DISTRIBUIDA', 'ROLE_EDITAR_APURACAO_VERBA_DISTRIBUIDA', 'ROLE_EDITAR_DISTRIBUICAO_LIDER_VERBA_DISTRIBUIDA']

  },
  {
    name: i18n.t('routes.distributionBudget.distributeMoney'),
    path: '/distribute-money',
    main: DistributeMoneyDistributionBudget,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_APURACAO_VERBA_DISTRIBUIDA', 'ROLE_EDITAR_APURACAO_VERBA_DISTRIBUIDA', 'ROLE_EDITAR_DISTRIBUICAO_LIDER_VERBA_DISTRIBUIDA']

  },
  {
    name: i18n.t('routes.distributionBudget.devolutionFunds'),
    path: '/reports/details-devolution',
    main: DevolutionBudgetReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_DEVOLUCAO_APURACAO_VERBA_DISTRIBUIDA']
  },
  {
    name: i18n.t('routes.distributionBudget.fundDistribution'),
    path: '/reports/details-distribution',
    main: DistributionBudgetReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_VERBAS_DISTRIBUIDAS_APURACAO_VERBA_DISTRIBUIDA']
  }
]

export default route
