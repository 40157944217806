import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Payments = lazy(() => lazyRetry(() => import('containers/Payments')))
const DetailedBudget = lazy(() => lazyRetry(() => import('containers/Payments/DetailedBudget')))
const Map = lazy(() => lazyRetry(() => import('containers/Payments/Map')))
const PaymentsReport = lazy(() => lazyRetry(() => import('containers/PaymentsReport')))
const ApprovePayments = lazy(() => lazyRetry(() => import('containers/ApprovePayments')))

const route = [
  {
    name: i18n.t('routes.payments.index'),
    path: '/payments',
    main: Payments,
    exact: true,
    permissions: [
      'ROLE_EDITAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_EDITAR_VERBAS_VALIDACAO',
      'ROLE_VISUALIZAR_PAGAMENTOS_CRIADOS',
      'ROLE_VISUALIZAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_VISUALIZAR_VERBAS_LIBERACAO',
      'ROLE_VISUALIZAR_VERBAS_VALIDACAO',
      'ROLE_EDITAR_PAGAMENTOS_CRIADOS',
      'ROLE_EDITAR_VERBAS_LIBERACAO'
    ]
  },
  {
    name: i18n.t('routes.payments.approve'),
    path: '/payments/approve',
    main: ApprovePayments,
    exact: true,
    permissions: [
      'ROLE_VISUALIZAR_APROVACAO_PAGAMENTO',
      'ROLE_APROVA_APROVACAO_PAGAMENTO'
    ]
  },
  {
    name: i18n.t('routes.payments.map'),
    path: '/payments/map',
    main: Map,
    exact: true,
    permissions: [
      'ROLE_VISUALIZAR_CADASTRO_BOA',
      'ROLE_EDITAR_CADASTRO_BOA'
    ]
  },
  {
    path: '/payments/detailed-budget/:ids/:lotCode',
    name: i18n.t('routes.payments.detailedBudget'),
    main: DetailedBudget,
    exact: true,
    permissions: [
      'ROLE_EDITAR_VERBAS_VALIDACAO',
      'ROLE_VISUALIZAR_VERBAS_VALIDACAO',
      'ROLE_EDITAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_VISUALIZAR_PAGAMENTOS_DISTRIBUICAO'
    ]
  },
  {
    path: '/payments/report',
    name: i18n.t('routes.payments.report'),
    main: PaymentsReport,
    exact: true,
    permission: ['ROLE_RELATORIO_PAGAMENTOS']
  }
]

export default route
